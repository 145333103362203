import React, { useState } from "react";
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import icon_report_red from '../../../../Assets/Icons/icon-report-red.svg';
import check_icon from '../../../../Assets/Icons/mdi-check-bold.svg';
import { strikeUser } from "../../../../lib/firebase/firestore";
import RadioInput from "../../../Common/Inputs/RadioInput";
import { useNavigate } from "react-router-dom";


interface Props {
    data: any;
    onCancel: any;
}

const reasons = [
    { value: "Conteúdo impróprio", label: "Conteúdo impróprio" },
    { value: "Importunação", label: "Importunação" },
    { value: "Perfil fake", label: "Perfil fake" },
    { value: "Spam", label: "Spam" },
];

const Report: React.FC<Props> = ({ data, onCancel }) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [reported, setReported] = useState<Boolean>(false);
    const navigate = useNavigate();
    const strikeHandler = async () => {
        await strikeUser(data.candidateId, data.reporterId, selectedOption);
        setReported(true);
    };



    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '1rem', width: '98%',
            marginBottom: 15,
            marginTop: 18
        }}>

            {reported
                ? (<>
                    <img src={check_icon} />
                    <div style={{ width: '60%', textAlign: 'center' }}>
                        <p className="title-interaction">Recebemos a sua denúncia</p >
                    </div>
                    <div style={{ width: '80%', textAlign: 'center', marginTop: -12, marginBottom: 12 }}>
                        <p style={{
                            color: "#919191",
                            fontFamily: "Work Sans",
                            fontSize: `1rem`,

                        }}>Obrigado por manter o Blinker um local seguro</p>
                    </div>
                    <div style={{ width: '94%' }}>
                        <DefaultButton type={"button"} text="OK" onClick={() => { onCancel(); navigate('/'); }} />
                    </div>

                </>)
                : (<>
                    <img src={icon_report_red} />
                    <p className="title-interaction">Denunciar usuário</p >

                    <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center', marginBottom: 18, marginTop: '1.25rem', width: '92%', gap: '0.81rem' }}>
                        {reasons.map(opt =>
                            <RadioInput
                                key={opt.value}
                                checked={selectedOption === opt.value}
                                label={opt.label}
                                value={opt.value}
                                onChange={(event: any) => setSelectedOption(event.target.value)}
                            />
                        )}
                    </div>
                    <div style={{ display: "flex", gap: '1rem', alignSelf: 'stretch' }}>
                        <div style={{ width: '50%' }}>
                            <DefaultButton type={"button"} text="Cancelar" onClick={onCancel} backgroundColor={"#919191"} textColor={"#1B1B1C"} />
                        </div>
                        <div style={{ width: '50%' }}>
                            <DefaultButton type={"button"} text="Reportar" onClick={strikeHandler} disabled={!Boolean(selectedOption)} />
                        </div>
                    </div>
                </>)
            }

        </div >
    )
};

export default Report;