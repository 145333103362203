import React, { useRef, useState } from 'react';
import '../../../../UI/Pages/App/Room/Menu.css';
import close_icon from '../../../../Assets/Icons/icon-close-white.svg'
import camera_icon from '../../../../Assets/Icons/icon-camera-oulined.svg'
import location_icon from '../../../../Assets/Icons/icon-location.svg';
import feedback_icon from '../../../../Assets/Icons/icon-feedback.svg';
import logout_icon from '../../../../Assets/Icons/tabler-logout.svg';
import useCurrentUser from '../../../State/Hooks/useCurrentUser';
import SquareIcon from '../../../Common/SquareIcon';
import DefaultModal from '../../../Common/DefaultModal';
import LeaveRoom from './LeaveRoom';
import Loading from '../../../Common/Loading';
import ErrorMessage from '../../../Common/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import PageTransition from '../../../Common/PageTransition';
import AppCamera from '../../../Common/AppCamera';
import DefaultButton from '../../../Common/Buttons/DefaultButton';
import reload_icon from '../../../../Assets/Icons/reload_icon.svg';
import ReturnButton from '../../../Common/Buttons/ReturnButton';
import { updateUserImage } from '../../../../lib/firebase/storage';

type Props = {
    // id: string;
    // name: string;
    // photo: string;
}
interface Selfie {
    file: Blob;
    url: string;
}
const Menu: React.FC<Props> = ({ }) => {
    const [showError, setShowError] = useState<boolean>(false);
    const { currentUser, setCurrentUser, removeCurrentUserRoom, currentRoom, todaySchedule } = useCurrentUser();
    const [showLeaveRoom, setShowLeaveRoom] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selfie, setSelfie] = useState<Selfie | null>(null);
    const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
    const [showCameraModal, setShowCameraModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const inputRef: any = useRef(null);
    const navigate = useNavigate();

    const leaveRoom = async () => {
        setIsLoading(true);
        try {
            if (currentUser.id) {
                removeCurrentUserRoom(currentUser.id);
            }
        } catch (error) {
            setShowError(true);
        }
        setIsLoading(false);
    }

    const handleCapture = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            if (e.target.files.length !== 0) {
                const file = e.target.files[0];
                const url = URL.createObjectURL(file);
                setSelfie({ file, url });
            }
        }
    }

    const updatePhoto = async () => {
        if (selfie) {
            setIsLoading(true);
            try {
                const photo = await updateUserImage(currentUser.id!, selfie.file);
                setCurrentUser(prev => ({ ...prev, photo }));
                setSelfie(null);
            } catch (error: any) {
                console.log(error)
                setErrorMessage(error)
                setShowError(true);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            {!isCameraOpen
                ? (
                    <PageTransition>
                        {selfie
                            ? (
                                <div className="take-selfie-container">

                                    <div className="image-container">
                                        <img src={selfie.url} alt="Selfie" className="selfie-image" />

                                    </div>
                                    <div className="actions">
                                        <ReturnButton type="button" onClick={() => setSelfie(null)} />
                                        <DefaultButton
                                            type="button"
                                            text={"Atualizar foto"}
                                            // iconRight={right_icon}
                                            // iconLeft={spacer_icon}
                                            disabled={!selfie}
                                            onClick={updatePhoto}
                                        />
                                    </div>

                                    <div onClick={() => { setSelfie(null); setShowCameraModal(true) }} >
                                        <img src={reload_icon} />
                                        <p className="take-selfie-remake-photo">Refazer foto</p>
                                    </div>
                                </div>
                            )
                            : (<>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }} onClick={() => navigate('/')}>
                                    <img src={close_icon} style={{ paddingRight: '0.1rem', margin: '1rem' }} />
                                </div>
                                <div className='menu-perfil-div'>
                                    <div className="menu-perfil-photo-container">
                                        <img className='menu-perfil-photo' src={currentUser?.photo} alt={currentUser?.name} />
                                        <div className="menu-camera-icon-container" onClick={() => setShowCameraModal(true)}>
                                            <img src={camera_icon} alt="camera icon" className="menu-camera-icon" />
                                        </div>
                                    </div>
                                    <p className='menu-perfil-name'>{currentUser.name}</p>
                                </div>
                                <div className='menu-location-div'>
                                    <div style={{ paddingBlock: '1rem', paddingLeft: '1rem' }}>
                                        <SquareIcon src={location_icon} />
                                    </div>
                                    <div className='menu-center-div' translate="no">
                                        <h2 className='menu-h2-title'>{currentRoom?.title}</h2>
                                        <p className='menu-p-text'>{currentRoom?.address}</p>
                                    </div>
                                </div>
                                <div className='menu-time-div'>
                                    <p className='menu-time-p'> Horário: {todaySchedule?.openTime} - {todaySchedule?.closeTime}</p>
                                </div>
                                <div className='menu-leave-app' >
                                    <div style={{ padding: 0, marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                        <div style={{ display: 'flex', alignContent: 'center', gap: '0.5rem', flex: 1, paddingLeft: '1rem' }} onClick={() => navigate('/feedback')} >
                                            <img src={feedback_icon} alt='Logoff' />
                                            <p className='menu-leave-p'>Deixe seu feedback</p>
                                        </div>
                                        <div style={{ display: 'flex', alignContent: 'center', gap: '0.5rem', flex: 1, paddingLeft: '1rem' }} onClick={() => setShowLeaveRoom(true)}>
                                            <img src={logout_icon} alt='Logoff' />
                                            <p className='menu-leave-p'>Sair da sala</p>
                                        </div>
                                    </div>
                                </div>

                                <input
                                    id="selfieInput"
                                    type="file"
                                    accept="image/*"
                                    // capture
                                    aria-label="Take Photo"
                                    onChange={handleCapture}
                                    ref={inputRef}
                                    style={{ display: 'none' }} // Esconde o input
                                />
                            </>)
                        }

                    </PageTransition >

                )
                : <AppCamera setImage={setSelfie} setIsOpen={setIsCameraOpen} />
            }


            <DefaultModal show={showCameraModal} onClose={() => setShowCameraModal(false)} showCloseButton={true}>
                <div className="camera-modal-main-div">
                    <p className="camera-modal-title">Abrir câmera</p>
                    {/* <DefaultButton onClick={() => { inputRef.current.click(); setShowCameraModal(false) }} text="Câmera do Celular" type={"button"} /> */}
                    <DefaultButton onClick={() => { inputRef.current.click(); setShowCameraModal(false) }} text="Galeria" type={"button"} />
                    <DefaultButton onClick={() => { setIsCameraOpen(true); setShowCameraModal(false) }} text="Câmera do App" type={"button"} backgroundColor="#1B1B1C" textColor="#FF6D8B" border="2px solid #FF6D8B" />
                </div>
            </DefaultModal>

            <DefaultModal show={showLeaveRoom} onClose={() => setShowLeaveRoom(false)}>
                <LeaveRoom
                    onCancel={() => setShowLeaveRoom(false)}
                    onSuccess={() => { setShowLeaveRoom(false); leaveRoom() }} />
            </DefaultModal>
            <Loading show={isLoading} />
            <ErrorMessage show={showError} onClose={() => setShowError(false)} />
        </>
    );
};

export default Menu;