import { useEffect, useState } from 'react';
import useCurrentUser from '../State/Hooks/useCurrentUser';
import MainRoutes from '../Pages/App/MainRoutes';
import LoginFlow from './Checkin/LoginFlow';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../../lib/firebase/client';
import Checkin from './Checkin/Checkin';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../lib/firebase/firestore';
import BanScreen from './BanScreen';

const ResolveAuth = () => {
  const [content, setContent] = useState<any>(null);
  const [dbUserId, setDbUserId] = useState<any>(null);

  const { currentUser, setCurrentUser, currentRoom, removeCurrentUserRoom } = useCurrentUser();

  const navigate = useNavigate();


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {

        if (!currentUser.id) {
          setDbUserId(user.uid);
        }

        if (currentUser?.strikes && currentUser?.strikes?.length > 2) {
          setContent(<BanScreen />);
          return;
        }

        if (currentUser.id && currentUser.roomId) {
          if (currentRoom?.open === false) {
            removeCurrentUserRoom(currentUser.id);
          } else {
            setContent(<MainRoutes />);
          }
        } else {
          setContent(<Checkin />);
          navigate('/');
        }
      } else {
        setContent(<LoginFlow />);
      }
    });

    return unsubscribe;
  }, [currentUser, currentRoom]);

  useEffect(() => {
    if (dbUserId) {
      const recoverDbUser = async () => {
        const dbUser = await getUser(dbUserId);
        if (dbUser) setCurrentUser(dbUser);
      }
      recoverDbUser();
    }
  }, [dbUserId]);


  return (<>
    {content}
  </>);
};

export default ResolveAuth;