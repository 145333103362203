import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentUserState } from "../atom";
import { useEffect, useState } from "react";
import { getMessagesToSnapshot, getUsersSnapshot, updateUser, getRoomsSnapshot } from "../../../lib/firebase/firestore";


const useCurrentUser = () => {
    const currentUser = useRecoilValue(currentUserState);
    const setCurrentUser = useSetRecoilState(currentUserState);
    const [messages, setMessages] = useState<any[]>([]);
    const startUsers: any[] = [];
    const [users, setUsers] = useState(startUsers);
    const usersList = users?.filter(user => user.id !== currentUser.id);
    const [roomsList, setRoomsList] = useState<any>();
    const currentRoom = roomsList?.find((room: any) => room.id === currentUser.roomId);
    const sortedMessages = messages?.sort(sortCreatedAtDesc);
    const sortedUsers = usersList?.sort(sortEntryTimeDesc);
    const today = new Date().toLocaleString('en-US', { weekday: 'long', timeZone: 'America/Sao_Paulo' }).toLowerCase();
    const todaySchedule = currentRoom?.schedule[today];

    useEffect(() => {
        getRoomsSnapshot(setRoomsList);
    }, []);

    useEffect(() => {
        const firebaseUser = users.find(u => u.id === currentUser.id);
        if (firebaseUser && JSON.stringify(firebaseUser) !== JSON.stringify(currentUser)) {
            setCurrentUser(firebaseUser);
        }
    }, [users]);

    useEffect(() => {
        localStorage.setItem('currentUser', JSON.stringify(currentUser));

        if (currentUser.id) {
            getMessagesToSnapshot(currentUser.id, setMessages);
        }
        if (currentUser.roomId) {
            getUsersSnapshot(currentUser.roomId, setUsers);
        }
    }, [currentUser]);

    const removeCurrentUserRoom = async (currentUserId: string) => {
        await updateUser(currentUserId, { roomId: null });
        setCurrentUser(prev => ({ ...prev, roomId: null }));
    };


    return {
        currentUser,
        setCurrentUser,
        removeCurrentUserRoom,
        messages: sortedMessages,
        usersList: sortedUsers,
        roomsList,
        currentRoom,
        todaySchedule
    };
}

const sortCreatedAtDesc = (a: any, b: any) => {
    if (a.createdAt?.seconds === b.createdAt?.seconds) {
        return b.createdAt.nanoseconds - a.createdAt.nanoseconds;
    }
    return b.createdAt?.seconds - a.createdAt?.seconds;
};

const sortEntryTimeDesc = (a: any, b: any) => {
    if (!a.entryTime) return 1;
    if (!b.entryTime) return -1;
    return b.entryTime.seconds - a.entryTime.seconds;
}

export default useCurrentUser;
