import '../../../UI/Pages/Checkin/UserForm.css';
import right_icon from '../../../Assets/Icons/icon-arrow-right.svg';
import spacer_icon from '../../../Assets/Icons/icon-spacer-24-px.svg';
import CheckinBackground from "./CheckinBackground";
import DefaultLabel from "../../Common/DefaultLabel";
import FormInput from "../../Common/Inputs/FormInput";
import { useEffect, useState, useRef } from "react";
import ReturnButton from "../../Common/Buttons/ReturnButton";
import DefaultButton from "../../Common/Buttons/DefaultButton";
import DefaultSubtitle from '../../Common/DefaultSubtitle';
import useCurrentUser from '../../State/Hooks/useCurrentUser';
import PageTransition from '../../Common/PageTransition';
import RadioInput from '../../Common/Inputs/RadioInput';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../lib/firebase/client";
import ErrorMessage from '../../Common/ErrorMessage';
import DefaultTitle from '../../Common/DefaultTitle';
import Loading from '../../Common/Loading';
import { createUser, getUserByPhone, updateUser } from '../../../lib/firebase/firestore';

interface Props {
    setPage: any;
}

const genders = [
    { value: "Homem", label: "Homem" },
    { value: "Mulher", label: "Mulher" },
    { value: "Outro", label: "Outro / Não especificar" },
];

const UserForm: React.FC<Props> = ({ setPage }) => {
    const { currentUser, setCurrentUser, currentRoom } = useCurrentUser();
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [selectedGender, setSelectedGender] = useState<string>('');
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [confirmationResult, setConfirmationResult] = useState<any>(null);
    const [errorTitle, setErrorTitle] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [resendTimer, setResendTimer] = useState<number>(0);
    const [step, setStep] = useState<number>(1); // Controla a etapa do formulário
    const recaptchaVerifier = useRef<RecaptchaVerifier | null>(null);


    useEffect(() => {
        if (currentUser) {
            setName(currentUser.name || '');
            setPhone(currentUser.phone || '');
            setSelectedGender(currentUser.gender || '');
        }
    }, [currentUser]);

    useEffect(() => {
        let interval: number | undefined;
        if (resendTimer > 0) {
            interval = window.setInterval(() => {
                setResendTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        if (interval !== undefined) {
                            clearInterval(interval);
                        }
                        return 0;
                    } else {
                        return prevTimer - 1;
                    }
                });
            }, 1000);
        }
        return () => {
            if (interval !== undefined) {
                clearInterval(interval);
            }
        };
    }, [resendTimer]);

    useEffect(() => {
        if (!recaptchaVerifier.current) {
            recaptchaVerifier.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',
                callback: (response: any) => {
                    console.log('reCAPTCHA resolvido');
                },
            });
        }

        return () => {
            if (recaptchaVerifier.current) {
                recaptchaVerifier.current.clear();
                recaptchaVerifier.current = null;
            }
        };
    }, []);


    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        if (value.length <= 11) {
            if (value.length > 2) {
                value = value.replace(/^(\d{2})(\d)/g, '($1) $2'); // Adiciona parênteses em torno dos dois primeiros dígitos
            }
            if (value.length > 7) {
                value = value.replace(/(\d)(\d{4})$/, '$1-$2'); // Adiciona um traço antes dos últimos quatro dígitos
            }
            setPhone(value);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (step === 1) {
            sendVerificationCode();
            setStep(2);
        } else if (step === 2) {
            verifyOtp();
        }
    };


    const sendVerificationCode = () => {
        if (!recaptchaVerifier.current) {
            console.error("reCAPTCHA não está inicializado");
            return;
        }

        const phoneNumber = "+55" + phone.replace(/\D/g, '');
        signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier.current)
            .then((result) => {
                setConfirmationResult(result);
                console.log("Código de verificação enviado");
                setResendTimer(60); // Inicia o temporizador de 60 segundos
            })
            .catch((error) => {
                console.log("Erro ao enviar código de verificação:", error.message);
                setErrorMessage(`Erro ao enviar código de verificação. ${error.message}`);
                setShowError(true);
            });
    };

    const verifyOtp = async () => {
        if (confirmationResult) {
            try {
                const result = await confirmationResult.confirm(verificationCode);
                const userId = result.user.uid;
                await collectionHandler(userId);
            } catch (error) {
                console.error("Erro ao verificar o código:", error);
                setErrorTitle('Código inválido')
                setErrorMessage('Por favor, tente novamente.');
                setVerificationCode('');
                setShowError(true);
                // Redefinir o recaptchaVerifier para permitir reenviar o código
                recaptchaVerifier.current?.clear();
                recaptchaVerifier.current = null;
            }
        }
    };


    const collectionHandler = async (userId: string) => {
        try {
            // Array.from({ length: 20 }).map(async () => {
            //     const newUser = await createUser(currentUser);
            //     const photo = await updateUserImage(newUser.id, selfie.file);
            // })

            const loggingUser = await getUserByPhone(phone);
            if (loggingUser) {
                await updateUser(loggingUser.id, { roomId: currentRoom.id, name });
                setCurrentUser(prev => ({ ...prev, ...loggingUser, name, gender: selectedGender }));
            } else {
                const newUser = await createUser({ name, phone, gender: selectedGender, roomId: currentRoom.id }, userId);
                setCurrentUser(newUser);
            }


        } catch (error: any) {
            console.log(error)
            setErrorMessage(error)
            setShowError(true);
        }
    }

    return (
        <CheckinBackground>
            <PageTransition>
                <div className='user-form-container'>
                    <form className="form-container" onSubmit={handleSubmit}>
                        {step === 1 && (
                            <>
                                <div className="form-group">
                                    <DefaultLabel text={"Seu nome"} htmlFor={"name"} />
                                    <FormInput
                                        type={"text"}
                                        id={"name"}
                                        value={name}
                                        placeholder={"Digite..."}
                                        maxLength={22}
                                        onChange={handleNameChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <DefaultLabel text={"Telefone"} htmlFor={"phone"} />
                                    <FormInput
                                        type={"text"}
                                        id={"phone"}
                                        value={phone}
                                        placeholder={"(00) 00000-0000"}
                                        onChange={handlePhoneChange} />
                                    <DefaultSubtitle text='*Este dado será oculto' />
                                </div>

                                <div className='user-form-gender'>
                                    <DefaultLabel text={"Gênero com o qual se identifica"} htmlFor={"gender"} />
                                    {genders.map(gender =>
                                        <RadioInput
                                            key={gender.value}
                                            checked={selectedGender === gender.value}
                                            label={gender.label}
                                            value={gender.value}
                                            onChange={(event: any) => setSelectedGender(event.target.value)}
                                        />
                                    )}
                                </div>

                                <div className="form-actions">
                                    <ReturnButton type="button" onClick={() => setPage(1)} />
                                    <DefaultButton
                                        type="submit"
                                        text={"Próximo"}
                                        iconRight={right_icon}
                                        iconLeft={spacer_icon}
                                        disabled={!name || phone.length < 15 || !selectedGender}
                                    />
                                </div>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', justifyContent: 'center', alignItems: 'center', marginTop: '3rem' }}>
                                    <DefaultTitle text={"Insira o código recebido por SMS"} />
                                    <FormInput
                                        type={"text"}
                                        id={"verificationCode"}
                                        value={verificationCode}
                                        placeholder={"XXXXXX"}
                                        maxLength={6}
                                        onChange={(e: any) => setVerificationCode(e.target.value)}
                                        centerText={true} />
                                    <div style={{ width: '75%' }}>
                                        <DefaultButton
                                            type="submit"
                                            text={"Verificar"}
                                            // iconRight={right_icon}
                                            // iconLeft={spacer_icon}
                                            disabled={verificationCode.length !== 6}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: '-1rem', textAlign: 'center' }}>
                                    <span className='form-span'>Não recebeu? </span>
                                    {resendTimer > 0
                                        ? (<span className='form-span'>Reenviar em {resendTimer}s</span>)
                                        : (<a className='form-span-white' href="#" onClick={(e) => { e.preventDefault(); sendVerificationCode(); }}>Reenviar</a>)
                                    }
                                </div>
                            </>
                        )}

                        <div style={{ display: 'none', flexDirection: 'column', gap: '1.5rem', justifyContent: 'center', alignItems: 'center' }}>
                            <div id="recaptcha-container" data-badge="inline" />
                        </div>
                    </form>
                </div>

            </PageTransition >
            <Loading show={isLoading} />
            <ErrorMessage show={showError} onClose={() => setShowError(false)} message={errorMessage} />
        </CheckinBackground >
    );
}

export default UserForm;