
import { RecoilRoot } from 'recoil';
import ResolveAuth from './Components/Pages/ResolveAuth';
import logo_extenso from './Assets/logos/logo_extenso.svg'
import { Route, Routes } from "react-router-dom";
import './App.css'; //deixar aqui


const App = () => {
  return (
    <RecoilRoot>
      {/* <div>V0.0001</div> */}

      <ResolveAuth />

    </RecoilRoot>
  )

  // const Pesquisa = () => {
  //   window.location.href = 'https://forms.gle/eWVSs4p3SSd2NoE66';
  //   return <></>;
  // }

  // return (

  //   <Routes>
  //     <Route path="/pesquisa" element={<Pesquisa />} />
  //     <Route path="*" element={<App />} />
  //   </Routes>

  // );



  //PARA FECHAR SALA E MANDAR PARA PESQUISA!!
  // window.location.href = 'https://forms.gle/eWVSs4p3SSd2NoE66';
  // return <></>;


}

export default App;
