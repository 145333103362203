import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Room from "./Room/Room";
import Notifications from "./Notifications/Notifications";
import ViewInteraction from "./Interactions/ViewInteraction";
import SendInteraction from "./Interactions/SendInteraction";
import Loading from "../../Common/Loading";
import Menu from "./Room/Menu";
import Feedback from "./Room/Feedback";


const MainRoutes = () => {

    return (
        <Routes>
            <Route path="*" element={<Suspense fallback={<Loading show={true} />}><Room /></Suspense>} />
            <Route path="/menu" element={<Suspense fallback={<Loading show={true} />}><Menu /></Suspense>} />
            <Route path="/blink/:id" element={<Suspense fallback={<Loading show={true} />}><SendInteraction /></Suspense>} />
            <Route path="/notifications" element={<Suspense fallback={<Loading show={true} />}><Notifications /></Suspense>} />
            <Route path="/notification/:id" element={<Suspense fallback={<Loading show={true} />}><ViewInteraction /></Suspense>} />
            <Route path="/feedback" element={<Suspense fallback={<Loading show={true} />}><Feedback /></Suspense>} />
        </Routes>
    );
}

export default MainRoutes;