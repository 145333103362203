import icon_report_red from '../../Assets/Icons/icon-report-red.svg'

const BanScreen = () => {

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '11.5rem'
        }}>

            <img src={icon_report_red} style={{ width: '2.5rem', height: '2.5rem' }} />

            <p style={{
                width: '60%',
                color: '#FFF',
                textAlign: 'center',
                fontFamily: "Work Sans",
                fontSize: '1rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
            }}
            >Sua conta está temporariamente suspensa</p>
        </div >
    )
}


export default BanScreen;