import '../../../UI/Pages/Checkin/Checkin.css'
import CheckinBackground from "./CheckinBackground";
import ItemList from "./ItemList";
import location_icon from '../../../Assets/Icons/icon-location.svg';
import icon_chevron_right from '../../../Assets/Icons/icon-chevron-right.svg';
import QrcodeButton from "../../Common/Buttons/QrcodeButton";
import DefaultTitle from "../../Common/DefaultTitle";
import PageTransition from '../../Common/PageTransition';
import useCurrentUser from '../../State/Hooks/useCurrentUser';

interface Props {
    setPage?: (page: any) => void;
}

const Checkin: React.FC<Props> = ({ setPage = () => { } }) => {

    const { roomsList } = useCurrentUser();

    return (
        <CheckinBackground>
            <PageTransition>
                <div className="checkin-container">
                    <DefaultTitle text="Você está em:" />
                    {roomsList?.map((room: any) =>
                        <ItemList
                            key={room.id}
                            room={room}
                            rightIcon={location_icon}
                            leftIcon={icon_chevron_right}
                            setPage={setPage}
                        />
                    )}

                </div>
                {/* <div className="qrcode-div">
                    <QrcodeButton />
                    </div> */}

            </PageTransition>
        </CheckinBackground>
    )
}


export default Checkin;