import { selector } from 'recoil';
import { User } from "../../Interfaces/index";
import { getUser } from '../../../lib/firebase/firestore';

function importAll(r: ReturnType<WebpackRequire['context']>) {
    let images: { [key: string]: string } = {};
    r.keys().forEach((item: string) => {
        images[item.replace('./', '')] = r(item);
    });
    return images;
}
export const loadEmojis = selector({
    key: 'loadEmojis',
    get: () => {
        const emojiData = [
            { id: '1', title: 'Timido(a)', text: 'está timido(a)' },
            { id: '2', title: 'Bora', text: 'respondeu: Bora!' },
            { id: '3', title: 'Só se for agora', text: 'respondeu: Só se for agora!' },
            { id: '4', title: 'Obrigado', text: 'te enviou: Obrigado' },
            { id: '5', title: 'De olho em outro(a)', text: 'está de olho em outra pessoa' },
            { id: '6', title: 'Sorry, hoje não', text: 'te enviou: Sorry, hoje não' },
            { id: '7', title: 'Piscadinha', text: 'te enviou uma piscadinha ;)' },
            { id: '8', title: 'Apaixonei', text: 'está apaixonadinho(a) por você <3' },
            { id: '9', title: 'Bora se beijar?', text: 'te enviou: Bora se beijar?' },
            { id: '10', title: 'Bora ser amigos?', text: 'quer ser seu amigo(a)' },
            { id: '11', title: 'Bora dançar?', text: 'te enviou: Bora dançar?' },
            { id: '12', title: 'Bora beber?', text: 'quer beber com você' },
        ];

        try {
            const regularImages = importAll(require.context('../../../Assets/emojis/regular', false, /\.png$/));
            const answerImages = importAll(require.context('../../../Assets/emojis/answer', false, /\.png$/));

            const listImages: { src: string; id: string, regular: boolean }[] = [];
            Object.keys(answerImages).map((key) => (
                listImages.push({ id: key.replace('.png', ''), src: answerImages[key], regular: false })
            ));
            Object.keys(regularImages).map((key) => (
                listImages.push({ id: key.replace('.png', ''), src: regularImages[key], regular: true })
            ));

            const combinedData = emojiData.map((emoji) => {
                const image = listImages.find(img => img.id === emoji.id);
                return {
                    ...emoji,
                    src: image ? image.src : '',
                    regular: image ? image.regular : false,
                };
            });

            combinedData.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))

            return combinedData;
        } catch (error) {
            return [];
        }
    }
});



const emptyUser: User = { id: '', name: '', photo: '', phone: '', deviceId: '', gender: '' };

const loadUserFromStorage = () => {
    const savedUser = localStorage.getItem('currentUser');
    return savedUser ? JSON.parse(savedUser) as User : emptyUser;
}


export const loadCurrentUser = selector<User>({
    key: 'loadCurrentUser',
    get: async () => {
        try {
            const savedUser = loadUserFromStorage();
            if (savedUser.id) {
                const firestoreUser = await getUser(savedUser.id);
                if (firestoreUser) {
                    return firestoreUser; // Carrega do Firestore se o usuário for encontrado
                }
            }
            return savedUser.id ? savedUser : emptyUser; // Carrega do local storage ou um usuário vazio
        } catch (error) {
            console.error('Erro ao carregar usuário:', error);
            return emptyUser;
        }
    }
});

