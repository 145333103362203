import '../../UI/Common/SquareIcon.css';

interface Props {
    src: string;
    alt?: string;
    long?: boolean;
}

const SquareIcon: React.FC<Props> = ({ src, alt, long }) => {
    return (
        <div className={long ? 'square-container-long' : 'square-container'}>
            <img src={src} alt={alt} />
        </div>
    )
}

export default SquareIcon;