import React, { useState } from "react";
import DefaultButton from "../../../Common/Buttons/DefaultButton";
import spacer_icon from '../../../../Assets/Icons/icon-spacer-24-px.svg';
import close_icon from '../../../../Assets/Icons/icon-close-white.svg';
import right_icon from '../../../../Assets/Icons/icon-arrow-right.svg';
import sent_icon from '../../../../Assets/Icons/icon-sent-pink.svg';
import AppBackground from "../AppBackground";
import PageTransition from "../../../Common/PageTransition";
import InteractionHeader from "../Interactions/InteractionHeader";
import { useNavigate } from "react-router-dom";
import FormInput from "../../../Common/Inputs/FormInput";
import useCurrentUser from "../../../State/Hooks/useCurrentUser";
import { sendFeedback } from "../../../../lib/firebase/firestore";
import DefaultModal from "../../../Common/DefaultModal";
import DefaultTitle from "../../../Common/DefaultTitle";
import DefaultLabel from "../../../Common/DefaultLabel";


const Feedback = () => {
    const navigate = useNavigate();
    const [likeMessage, setLikeMessage] = useState<string>();
    const [changeMessage, setChangeMessage] = useState<string>();
    const [isFeedbackSent, setIsFeedbackSent] = useState<boolean>(false);
    const { currentUser } = useCurrentUser();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        sendFeedback(currentUser.id!, likeMessage ?? '', changeMessage ?? '');
        setIsFeedbackSent(true);
    };

    return (
        <AppBackground>
            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <PageTransition>
                    <div className="send-interaction-main-div">
                        <InteractionHeader
                            leftButton={
                                <button className='interaction-header-icon-button' >
                                    <img src={spacer_icon} alt="Voltar" style={{ paddingLeft: '2rem' }} />
                                </button>}
                            rightButton={
                                <button className='interaction-header-icon-button' onClick={() => navigate('/menu')}>
                                    <img src={close_icon} alt="Reportar" style={{ height: 28, paddingRight: '1.5rem' }} />
                                </button>
                            }
                            title={"Deixe seu feedback"}
                        />
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            gap: '2rem',
                            marginTop: '4.5rem',
                            alignItems: 'center',
                            paddingInline: '1rem',
                        }}>
                            <div style={{ display: "flex", flexDirection: 'column', width: '100%' }}>
                                <p style={{
                                    color: 'white',
                                    fontFamily: "Work Sans",
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: 'normal',
                                    alignSelf: 'stretch'
                                }}>
                                    O que você mais gostou no Blinker?
                                </p>
                                <FormInput
                                    type={"textarea"}
                                    id={"likeMessage"}
                                    rows={3}
                                    value={likeMessage}
                                    placeholder={"Digite..."}
                                    onChange={(e: any) => setLikeMessage(e.target.value)}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: 'column', width: '100%', marginTop: -15 }}>
                                <p style={{
                                    color: 'white',
                                    fontFamily: "Work Sans",
                                    fontSize: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    lineHeight: 'normal',
                                    alignSelf: 'stretch'
                                }}>
                                    O que você mudaria no Blinker?
                                </p>
                                <FormInput
                                    type={"textarea"}
                                    id={"changeMessage"}
                                    rows={3}
                                    value={changeMessage}
                                    placeholder={"Digite..."}
                                    onChange={(e: any) => setChangeMessage(e.target.value)}
                                />
                            </div>
                        </div>

                        <div style={{ paddingInline: '1rem', marginTop: '0.5rem' }}>
                            <DefaultButton type={"submit"} text="Enviar" iconRight={right_icon} disabled={!(Boolean(likeMessage) || Boolean(changeMessage))} />
                        </div>
                    </div >
                </PageTransition>
            </form >
            <DefaultModal show={isFeedbackSent} onClose={() => navigate('/menu')} showCloseButton={true}>
                <div>
                    <div style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>

                        <div style={{ marginTop: '2.5rem' }}>
                            <img src={sent_icon} />
                        </div>
                        <p style={{
                            color: '#FFF',
                            fontFamily: "Work Sans",
                            fontSize: '1.25rem',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal',
                            marginTop: 15,
                        }}>
                            Muito obrigado!
                        </p>

                        <p style={{
                            marginTop: 5,
                            marginBottom: 40,
                            width: '80%',
                            color: '#919191',
                            textAlign: 'center',
                            fontFamily: "Work Sans",
                            fontSize: '1rem',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: 'normal',
                        }}>
                            Seu feedback é muito importante para nós!
                        </p>
                    </div>

                </div>
            </DefaultModal>
        </AppBackground >
    )
};

export default Feedback;